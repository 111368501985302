body {
    background: #bdbdbd !important;
}



.label-checkbox100::before {
    content: "\f26b";
    font-family: Material-Design-Iconic-Font;
    font-size: 13px;
    color: transparent;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    background: #ec0127 !important;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.input100 {
    font-size: 16px;
    color: black;
    line-height: 1.2;
    display: block;
    width: 100%;
    height: 45px;
    background: transparent;
    padding: 0px 5px 0 6px !important;
}

.login100-form {
    width: 90%;
}

.wrap-input100 {
    width: 100% !important;
}

::placeholder {
    color: black !important;
}

.detail_content {
    text-align: center;
    margin-top: -82px;
    padding-left: 250px;
}

.logo img {
    width: 450px !important;
}

header {
    background-color: rgba(255,255,255,0.9);
    border-bottom: 1px solid #ec0226;
}

.btn_register {
    border: none;
    padding: 10px 15px;
    color: white;
    background: #ec0226;
    border-radius: 0px;
}

.bg_heightlight {
    background-color: #ffd704;
    color: black !important;
    font-weight: 500;
}

.right_header {
    text-align: right;
    justify-content: right !important;
    margin-top: -70px;
    padding-right: 100px;
}

.bright {
    border-right: 1px solid #ec0226;
}

.wrap-login100 {
    width: 600px;
    border-radius: 10px;
    overflow: hidden;
    padding: 20px !important;
    padding: 0px 0px 0px 0px;
    background: white;
    box-shadow: none !important;
    border-radius: 5px;
}

.detail_content h6 {
    margin-top: 2px;
}

.login_section {
    margin-left: 13px;
}

.text_login {
    margin-top: 80px;
}

.txt1 {
    font-size: 20px;
    background-color: #ffd704;
    padding: 8px;
    border-radius: 0px 16px 16px 0px;
}

.text_login a:hover {
    color: black;
}

.new_register {
    background-color: rgba(255,255,255,0.9);
    padding-top: 20px;
    padding-bottom: 17px;
    margin-bottom: 0px;
}

.text_pdf {
    margin-top: -50px;
    margin-bottom: 15px;
}

.text_pdf_login {
    margin-top: 0px;
    margin-bottom: 15px;
}

.login_section .btn_register {
    background: #2e3458;
}

.btn_register:hover {
    color: white !important;
}

.bg_gray {
    background: #ec0226 !important;
}

.bg_CIs {
    background: #4a5191 !important;
}

.darkblue {
    background: #2e3458 !important;
}

table th {
    background: #39f !important;
    color: white;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

table {
    border-color: #717172 !important;
    margin-bottom: 10px !important;
}

body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.row_forcontent {
    margin-top: 0px;
}

.links_use {
    width: 630px;
}

    .links_use ul {
        margin: 0px;
        padding: 0px;
    }

        .links_use ul li {
            margin: 0px 10px;
            list-style: none;
        }

            .links_use ul li a {
                color: black;
                font-weight: 500;
            }

                .links_use ul li a:hover {
                    color: red;
                }

.text_pdf a {
    margin-bottom: 10px;
}

    .text_pdf a:hover {
        background: #2e3458 !important;
    }

.links_use p {
    color: black
}

@media only screen and (max-width: 600px) {
    .wrap-login100 {
        width: 100% !important;
    }

    .detail_content {
        text-align: center;
        margin-top: -70px;
        padding: 10px;
        margin-top: 11px;
        margin-bottom: 13px;
    }

    .right_header {
        text-align: center;
        justify-content: right !important;
        margin-top: -40px;
        margin-bottom: 30px;
        padding: 0px;
    }

    .text_login {
        margin-top: 0px;
    }

    .selector {
        margin-top: 20px;
    }

    .login_section {
        margin-left: 0px;
    }

    .logo img {
        width: 100% !important;
    }

    .links_use {
        margin-top: 113px;
    }

    .btn_register {
        margin-bottom: 20px;
    }

    .links_use {
        width: 100%;
    }
}

@media (max-width: 992px) {
    .detail_content {
        text-align: center;
        margin-top: 0px;
        padding-left: 0px;
    }

    .logo img {
        width: 100% !important;
    }

    .wrap-login100 {
        width: 100%;
    }

    .links_use {
        width: 100%;
    }
}

@media (max-width: 1200px) {
    .detail_content {
        text-align: center;
        margin-top: 0px;
        padding-left: 0px;
    }

    .logo img {
        width: 100% !important;
    }

    .wrap-login100 {
        width: 100%;
    }

    .links_use {
        width: 100%;
    }
}